import classes from './Styles.module.css'
import MDEditor from '@uiw/react-md-editor';
import Text from '../../Text/Text/Text';

const CustomMarkdown = (props) => {
    const { value, onChange, label, error } = props

    return (
        <div className={classes.main}>
            {label && <label>{label}</label>}
            <MDEditor
                value={value}
                onChange={onChange}
                className={classes.editor}
                preview="edit"
                style={{ whiteSpace: 'pre-wrap',  }}
            />
            {error && (
                <Text variant="error">{error.message}</Text>
            )}
        </div>
    )
}

export default CustomMarkdown