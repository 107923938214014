import classes from '../AdminView.module.css'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import StatusDropdown from './StatusDropdown/StatusDropdown'
import moment from "moment"

const Orders = (props) => {
    const {
        orders,
        pageSize,
        pageNumber,
        total,
        handleChangePage,
        handlePageSize,
        handleRemove,
        handleEditStatus,
        searchValue,
        getOrders
    } = props

    const rows = [
        {
            key: 'number',
            text: "Номер",
            searchByValue: "number"
        },
        {
            key: 'name',
            text: "Ім'я",
            searchByValue: "name"
        },
        {
            key: "email",
            text: "Email",
            searchByValue: "email"
        },
        {
            key: "phone",
            text: "Телефон",
            searchByValue: "phone"
        },
        {
            key: "",
            text: "Статус",
            searchByValue: ""
        },
        {
            key: "createdAt",
            text: "Дата",
            searchByValue: "createdAt"
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getOrders}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell width={100} align="center">{item.number}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.phone}</TableCell>
                                <TableCell width={180} style={{ zIndex: orders.length - index }}>
                                    <StatusDropdown
                                        value={item.status}
                                        zIndex={orders.length - index}
                                        onSelect={handleEditStatus}
                                        orderId={item._id}
                                    />
                                </TableCell>
                                <TableCell width={120}>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</TableCell>
                                <TableCell width={90} align="center">
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {orders.length === 0 && <EmptyData/>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component={"div"}
                rowsPerPage={pageSize}
                page={pageNumber}
                count={total}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handlePageSize}
                className={classes.pagination}
            />
        </div>
    )
}

export default Orders