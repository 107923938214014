import { Button } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAdminSidebar } from '../../../../Hooks/useAdminSidebar'
import classes from './Sidebar.module.css'
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { connect } from 'react-redux'
import { logout } from '../../../../Redux/userReducer'
import { Divider } from '@mui/material'

const Sidebar = (props) => {
    const { logout } = props

    const menuItems = useAdminSidebar()

    return (
        <div className={classes.main}>
            {menuItems.map(item => (
                <Button key={item.title} className={classes.linksBut}>
                    <NavLink to={item.href} className={({ isActive }) => isActive ? classes.active : ''}>
                        {item.icon}
                        <span>{item.title}</span>
                    </NavLink>
                </Button>
            ))}
            <Divider className={classes.divider}/>
            <Button className={classes.logout} onClick={logout}>
                <RiLogoutBoxRLine/>
                <span>Вихід</span>
            </Button>
        </div>
    )
}

export default connect(null, {
    logout
})(Sidebar)