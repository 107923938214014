import classes from '../AdminView.module.css'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'

const Constructions = (props) => {
    const {
        constructions,
        pageSize,
        pageNumber,
        // total,
        // handleChangePage,
        // handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getConstructions
    } = props

    const rows = [
        {
            key: 'image',
            text: 'Фото',
            searchByValue: ""
        },
        {
            key: "name",
            text: "Назва",
            searchByValue: ""
        },
        {
            key: "brand",
            text: "Бренд",
            searchByValue: ""
        },
        {
            key: "type",
            text: "Категорія",
            searchByValue: ""
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getConstructions}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {constructions.map(item => (
                            <TableRow key={item._id}>
                                <TableCell width={"100px"}>
                                    <img src={item.image} alt="poster" className={classes.imgPreview}/>
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.brand}</TableCell>
                                <TableCell>{item.type === "light" ? "Світлопрозорі" : "Алюмінієві"}</TableCell>
                                <TableCell width={90}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {constructions.length === 0 && <EmptyData/>}
        </div>
    )
}

export default Constructions