import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import classes from './AdminLayout.module.css'

const AdminLayout = (props) => {
    const { children } = props

    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <Sidebar/>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AdminLayout