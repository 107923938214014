import classes from '../AdminView.module.css'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import PreviewSlide from './PreviewSlide/PreviewSlide'

const Slides = (props) => {
    const {
        slides,
        pageSize,
        pageNumber,
        // total,
        // handleChangePage,
        // handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getSlides
    } = props

    const rows = [
        {
            key: 'slide',
            text: 'Слайд',
            searchByValue: ""
        },
        {
            key: "type",
            text: "Розділ",
            searchByValue: ""
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getSlides}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slides.map(item => (
                            <TableRow key={item._id}>
                                <TableCell width={"100px"}>
                                    <PreviewSlide item={item}/>
                                </TableCell>
                                <TableCell>{item.type === "karp" ? "Карпорти" : "Тераси"}</TableCell>
                                <TableCell width={90}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {slides.length === 0 && <EmptyData/>}
        </div>
    )
}

export default Slides