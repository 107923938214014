import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { deleteOrder, getOrders, setNewOrder, setOrdersData, setTotalOrders, updateOrder } from "../../../Redux/ordersReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
// import OrderCreate from "./OrderCreate"
import Orders from "./Orders"
// import OrderUpdate from "./OrderUpdate"

const OrdersContainer = (props) => {
    const {
        isFetching,
        getOrders,
        setOrdersData,
        setNewOrder,
        newOrder,
        orders,
        total,
        updateOrder,
        deleteOrder,
        setTotalOrders
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleEditStatus = (orderId, status) => {
        updateOrder(orderId, status)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleRemove = (orderId) => {
        deleteOrder(orderId).then((status) => {
            if(status) {
                const newOrders = [...orders]
                newOrders.forEach((item, index) => {
                    if(item._id === orderId){
                        newOrders.splice(index, 1)
                        setTotalOrders(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setOrdersData(newOrders)
            }
        })
    }

    useEffect(() => {
        if(newOrder){
            const newOrders = [...orders]
            let pushIndex = newOrders.length
            newOrders.forEach((item, index) => {
                if(item._id === newOrder._id){
                    newOrders.splice(index, 1)
                    pushIndex = index
                }
            })
            newOrders.splice(pushIndex, 0, newOrder)
            setOrdersData(newOrders)
            setNewOrder(null)
        }
    }, [newOrder])

    useEffect(() => {
        getOrders(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Заявки" 
                createBut={false}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getOrders}
            />
            {/* {isOpenEdit && <OrderUpdate onClose={handleOpenEdit} editOrder={updateOrder} order={currentItem}/>} */}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={`заявку №${currentItem.number}`}
                />
            )}
            {isFetching && <Preloader/>}
            {orders && (
                <Orders
                    orders={orders}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    total={total}
                    handleChangePage={handleChangePage}
                    handlePageSize={handlePageSize}
                    handleEditStatus={handleEditStatus}
                    handleRemove={handleOpenRemove}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    getOrders={getOrders}
                />
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newOrder: state.orders.newOrder,
    orders: state.orders.orders,
    total: state.orders.total
})

export default connect(mapStateToProps, {
    getOrders,
    updateOrder,
    deleteOrder,
    setNewOrder,
    setTotalOrders,
    setOrdersData
})(OrdersContainer)