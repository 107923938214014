import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Admin from '../../Pages/Admin/Admin'
import Login from '../../Pages/Auth/Login/Login'
import ClientsContainer from '../Admin/Clients/ClientsContainer'
import ConstructionsContainer from '../Admin/Constructions/ConstructionsContainer'
import OrdersContainer from '../Admin/Orders/OrdersContainer'
import SiteInfoContainer from '../Admin/SiteInfo/SiteInfoContainer'
import SlidesContainer from '../Admin/Slides/SlidesContainer'
import VideosContainer from '../Admin/Videos/VideosContainer'

const RoutesComponent = () => {
    return (
        <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="" element={<Admin/>}>
                <Route path="/" element={<OrdersContainer/>} />
                <Route path="clients" element={<ClientsContainer/>} />
                <Route path="constructions" element={<ConstructionsContainer/>} />
                <Route path="slides" element={<SlidesContainer/>} />
                <Route path="videos" element={<VideosContainer/>} />
                <Route path="settings" element={<SiteInfoContainer/>} />
                {/* <Route path="promocodes" element={<PromocodesContainer/>} /> */}
            </Route>
        </Routes>
    )
}

export default RoutesComponent