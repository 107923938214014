import { combineReducers, applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import multi from 'redux-multi';
import userReducer from './userReducer';
import commonReducer from './commonReducer';
import ordersReducer from './ordersReducer';
import clientsReducer from './clientsReducer';
import constructionsReducer from './constructionsReducer';
import videosReducer from './videosReducer';
import slidesReducer from './slidesReducer';

let reducers = combineReducers({
    user: userReducer,
    common: commonReducer,
    orders: ordersReducer,
    clients: clientsReducer,
    constructions: constructionsReducer,
    videos: videosReducer,
    slides: slidesReducer
})

let store = createStore(reducers, applyMiddleware(thunk, multi))

window.store = store

export default store