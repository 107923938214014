import axios from "axios"

export const baseURL = process.env.REACT_APP_API_URL

const instance = axios.create({
    baseURL: baseURL
})

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.accessToken
        if (token) {
            config.headers.authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error)
)

export const authApi = {
    login(email, password) {
        return instance
            .post(`/admin/login`, { email, password })
            .then((response) => response.data)
    },
    me() {
        return instance.get("/admin/auth").then((response) => response.data)
    }
}

export const ordersApi = {
    getOrders(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance.get(`/order?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`)
        .then(response => response.data)
    },
    updateOrder(orderId, status) {
        return instance.patch(`/order/${orderId}`, { status })
        .then(response => response.data)
    },
    deleteOrder(orderId) {
        return instance.delete(`/order/${orderId}`)
        .then(response => response.data)
    }
}

export const clientsApi = {
    getClients() {
        return instance.get("/client")
        .then(response => response.data)
    },
    createClient(data) {
        const image = data.image

        delete data.image
        return instance.post("/client", {
            image: image, ...data
        }, 
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    updateClient(id, data) {
        const image = data.image

        delete data.image

        return instance.patch(`/client/${id}`, {
            image: image,
            ...data
        },
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    deleteClient(id) {
        return instance.delete(`/client/${id}`)
        .then(response => response.data)
    }
}

export const constructionsApi = {
    getConstructions() {
        return instance.get("/construction")
        .then(response => response.data)
    },
    createConstruction(data) {
        const image = data.image

        delete data.image
        return instance.post("/construction", {
            image: image, ...data
        }, 
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    updateConstruction(id, data) {
        const image = data.image

        delete data.image

        return instance.patch(`/construction/${id}`, {
            image: image,
            ...data
        },
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    deleteConstruction(id) {
        return instance.delete(`/construction/${id}`)
        .then(response => response.data)
    }
}

export const videosApi = {
    getVideos() {
        return instance.get(`/video`)
        .then(response => response.data)
    },
    createVideo(data) {
        return instance.post(`/video`, data)
        .then(response => response.data)
    },
    updateVideo(videoId, data) {
        return instance.patch(`/video/${videoId}`, data)
        .then(response => response.data)
    },
    deleteVideo(videoId) {
        return instance.delete(`/video/${videoId}`)
        .then(response => response.data)
    }
}

export const slidesApi = {
    getSlides() {
        return instance.get("/slide")
        .then(response => response.data)
    },
    createSlide(data) {
        const bg = data.bg
        const images = data.images

        delete data.bg
        delete data.images

        return instance.post("/slide", {
            bg: bg,
            images: images,
            ...data
        }, 
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    updateSlide(id, data) {
        const bg = data.bg
        const images = data.images

        delete data.bg
        delete data.images

        return instance.patch(`/slide/${id}`, {
            bg: bg,
            images: images,
            ...data
        },
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(response => response.data)
    },
    deleteSlide(id) {
        return instance.delete(`/slide/${id}`)
        .then(response => response.data)
    }
}

export const siteInfoApi = {
    getSiteInfo() {
        return instance.get("/common")
        .then(response => response.data)
    },
    updateSiteInfo(data) {
        return instance.patch("/common", data)
        .then(response => response.data)
    }
}