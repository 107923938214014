import * as React from "react"
import classes from "./Preloader.module.css"

const Preloader = (props) => (
    <div className={classes.main}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1278 1230"
            {...props}
        >
            <path
            fill="#F5F5F5"
            d="M217 662H24v77.5h177c75 21 71.5 157.5-36.5 134.5V760.5c-23.2-5.6-68.333 5.667-88 12v302h88V954.999H221C429 892.499 330 662 217 662Z"
            className={classes["svg-elem-1"]}
            />
            <path
            fill="#F5F5F5"
            d="M373.5 1074.5H461v-336h44.5c62.5 12 57 97 32.5 116.5s-55.5 44.5-58 51-1.667 17.667 0 25.5l91 143h105l-97.5-150c125-73 44-262.5-38.5-262.5H373.5v412.5Z"
            className={classes["svg-elem-2"]}
            />
            <path
            fill="#F5F5F5"
            d="M1119 790h-89.5V662.5H1278v76h-159V790Z"
            className={classes["svg-elem-3"]}
            />
            <path
            fill="#F5F5F5"
            d="M1230.5 822h-201v251.5h89.5v-171h54.5l57-80.5Z"
            className={classes["svg-elem-4"]}
            />
            <path
            fill="#F5F5F5"
            d="M169.5 1229.5H96c-15.5 0-15.5-27 0-27h73.5c20.5 4.5 11.5 27 0 27Z"
            className={classes["svg-elem-5"]}
            />
            <path
            fill="#F5F5F5"
            d="M1232.5 1202.5h-70c-17 0-19 27 0 27h70c21-8.5 11-27 0-27Z"
            className={classes["svg-elem-6"]}
            />
            <path
            fill="#F5F5F5"
            d="M308.5 1222.5H297v-92h17l24 51 22.5-51h17v92h-12V1144l-23 52.5H333l-24.5-52.5v78.5Z"
            className={classes["svg-elem-7"]}
            />
            <path
            fill="#F5F5F5"
            d="M562 1131h-12v91h12v-44.5h46v44.5h12v-91h-12v36h-46v-36Z"
            className={classes["svg-elem-8"]}
            />
            <path
            fill="#F5F5F5"
            d="M657.5 1131v10.5H690v80.5h11.5v-80.5h32V1131h-76Z"
            className={classes["svg-elem-9"]}
            />
            <path
            fill="#F5F5F5"
            d="M773 1222h-12.5l32.5-91h17.5l32.5 91h-12.5l-29-80.5-15.755 44.5H815l3.5 11h-36.649L773 1222Z"
            className={classes["svg-elem-10"]}
            />
            <path
            fill="#F5F5F5"
            d="M875 1131v11h11c7.5 3.5 6.5 6.5 9.5 17.5s13 15 13 15l-36 47H886l32.5-41.5h6v41.5H936V1180h7l31 41.5h14.5l-35-47s11-8.5 12.5-15 4.5-17.5 9.5-17.5H986v-11h-10.5s-12 3-14.5 9c-14 38-25 32-25 32v-41h-11.5v41c-17-1.5-20.5-25.5-24.5-32s-14-9-14-9h-11Z"
            className={classes["svg-elem-11"]}
            />
            <path
            fill="#F5F5F5"
            d="M683.5 946V803.5l91 7.5v125c9.2 41.6 48.5 47 67 44.5 43-6 53-44.5 53-44.5V811l89-7.5V946c-18.4 92-102.333 119.67-142 122-111.6-.8-151.833-81.667-158-122Z"
            className={classes["svg-elem-12"]}
            />
            <path
            fill="#F5F5F5"
            d="M983.5 803.5s-69.5 52.5-89 7.5-41.167-43.667-56.5-47.5c-48 .4-62.333 31.833-63.5 47.5l-91-7.5c17.5-129 154.5-124 154.5-124 103.2 5.2 140 84.833 145.5 124Z"
            className={classes["svg-elem-13"]}
            />
            <path
            fill="#F5F5F5"
            d="M422.5 1186v-17.5H435v17.5c2 17.6 13.833 25 19.5 26.5 4 2 14.333.83 19 0 12.8-2 18.667-18.5 20-26.5h13c-4.8 28-24 36.33-33 37h-19c-23.2-2.8-31-25.83-32-37Z"
            className={classes["svg-elem-14"]}
            />
            <path
            fill="#F5F5F5"
            d="M456 1140c-16 4-20.667 20.67-21 28.5h-12.5c3.5-37.5 33.5-39 33.5-39h17c25.6 5.2 33 28.17 33.5 39v17.5h-13l.5-17.5c-2-18.4-14.833-26.67-21-28.5-6-2-13.833-.83-17 0Z"
            className={classes["svg-elem-15"]}
            />
            <path
            fill="#F5F5F5"
            d="M0 477v16.5l413.5-307-10-10.5L0 477Z"
            className={classes["svg-elem-16"]}
            />
            <path
            fill="#F5F5F5"
            d="M852.025.5H853L837.873 8l1.627 3.5-567 462.5L0 493.5l20-15 247-18 526.5-430-381 156.5-.432-.528-.068.028-8.5-8.5v-2L828 0h25l-.975.5Z"
            className={classes["svg-elem-17"]}
            />
            <path
            fill="#F5F5F5"
            d="M1215.5 414 837.5 8l14-8 364 392.5V414Z"
            className={classes["svg-elem-18"]}
            />
            <path
            fill="#F5F5F5"
            d="m840.5 10.5-1 1-1.5-3 2.5 2Z"
            className={classes["svg-elem-19"]}
            />
            <path fill="#F5F5F5" d="M853 2V0h-1v1l1 1Z" className={classes["svg-elem-20"]} />
            <path
            fill="#F5F5F5"
            d="M28 512v-13l252.5-14.5 559-455L1206 415v13.5h-82.5V415h61.5L839.5 51l-25.259 20.5-1.741 86 42-50.5 16.5 13.5-51 63.5-44-3 15.5-16.5V89.957L752 122.014 736 135 490.5 334.246l633 32.254-15 12.5v70.5l15 15.5-251-7.913L875 617h-16V456.662l-9.5-.3 2 160.638H837V455.968l-78.5-2.475V622.5H746l-2.5-169.479-4-.127V622.5H726V452.469L441.5 443.5v-15l667 21V379L474 347.638 287.5 499 28 512Z"
            className={classes["svg-elem-21"]}
            />
            <path
            fill="#F5F5F5"
            d="M775.368 178.725 737 135.5l-1.5-1.5 16.5-13 39 44-12.204 14.011L952 193.5l8.5 14.5-287-25 15.5-11.5 86.368 7.225Z"
            className={classes["svg-elem-22"]}
            />
            <path
            fill="#F5F5F5"
            d="m960.5 214.5 8 11.5-98-8.61V354.5h-19V215.72l-97.5-8.566V348h-19.5V205.441l-79-6.941 12.5-11 292.5 27Z"
            className={classes["svg-elem-23"]}
            />
            <path
            fill="#F5F5F5"
            d="M1108.5 449.5V379l15-12.5V465l-15-15.5Z"
            className={classes["svg-elem-24"]}
            />
            <path
            fill="#F5F5F5"
            d="m373.5 429 14.5-12.5V622l40 12V384l15.5-12.5v295H428v-16.25L388 637v29.5h-14.5V429Z"
            className={classes["svg-elem-25"]}
            />
            <path
            fill="#F5F5F5"
            d="M459 637h-11V442.5h11v181.398L771.5 621v73l-13 5v-62H459Z"
            className={classes["svg-elem-26"]}
            />
            <path
            fill="#F5F5F5"
            d="M1113.5 465v147.206l11-.206v53H1111v-38.5L844.5 629v53h-14v-64.5l252-4.714V463.5h13.5v149.033l4.98-.093-.98-148.94 13.5 1.5Z"
            className={classes["svg-elem-27"]}
            />
            <path
            fill="#F5F5F5"
            d="M70 666.5V510l16-3v159.5H70Z"
            className={classes["svg-elem-28"]}
            />
            <path
            fill="#F5F5F5"
            d="M146 666.5V504h13v162.5h-13Z"
            className={classes["svg-elem-29"]}
            />
            <path
            fill="#F5F5F5"
            d="M268 697.5V498l14.5-2.5v202H268Z"
            className={classes["svg-elem-30"]}
            />
        </svg>
    </div>
)
export default Preloader
