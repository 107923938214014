import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createClient, deleteClient, getClients, setNewClient, setClientsData, setTotalClients, updateClient } from "../../../Redux/clientsReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import ClientCreate from "./ClientCreate"
import Clients from "./Clients"
import ClientUpdate from "./ClientUpdate"

const ClientsContainer = (props) => {
    const {
        isFetching,
        getClients,
        createClient,
        setClientsData,
        setNewClient,
        newClient,
        clients,
        total,
        updateClient,
        deleteClient,
        setTotalClients
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createClient(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (clientId) => {
        deleteClient(clientId).then((status) => {
            if(status) {
                const newClients = [...clients]
                newClients.forEach((item, index) => {
                    if(item._id === clientId){
                        newClients.splice(index, 1)
                        setTotalClients(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setClientsData(newClients)
            }
        })
    }

    useEffect(() => {
        if(newClient){
            const newClients = [...clients]
            let pushIndex = newClients.length
            newClients.forEach((item, index) => {
                if(item._id === newClient._id){
                    newClients.splice(index, 1)
                    pushIndex = index
                }
            })
            newClients.splice(pushIndex, 0, newClient)
            setClientsData(newClients)
            setTotalClients(total + 1)
            setNewClient(null)
        }
    }, [newClient])

    useEffect(() => {
        getClients(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Клієнти" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getClients}
                searchBar={false}
            />
            {isOpenAdd && <ClientCreate onClose={handleOpenAdd} addClient={handleAdd}/>}
            {isOpenEdit && <ClientUpdate onClose={handleOpenEdit} editClient={updateClient} client={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.name}
                />
            )}
            {isFetching && <Preloader/>}
            {clients && (
                <Clients
                    clients={clients}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    total={total}
                    handleChangePage={handleChangePage}
                    handlePageSize={handlePageSize}
                    handleEdit={handleOpenEdit}
                    handleRemove={handleOpenRemove}
                    onAddClick={handleOpenAdd}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    getClients={getClients}
                />
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newClient: state.clients.newClient,
    clients: state.clients.clients,
    total: state.clients.total
})

export default connect(mapStateToProps, {
    getClients,
    createClient,
    updateClient,
    deleteClient,
    setNewClient,
    setTotalClients,
    setClientsData
})(ClientsContainer)