import classes from "./SiteInfo.module.css"
import { Controller, useForm } from "react-hook-form"
import { useEffect } from "react"
import CustomInput from "../../UI/Form/Input"
import CustomButton from "../../UI/CustomButton/CustomButton"

const SiteInfo = (props) => {
    const { site_info, updateSiteInfo } = props

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        updateSiteInfo(data)
    }

    useEffect(() => {
        reset({
            seo_keywords: site_info.seo_keywords
        })
    }, [])

    return (
        <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="seo_keywords"
                control={control}
                rules={{ required: "Це поле не може бути пустим" }}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CustomInput
                        onChange={onChange}
                        value={value}
                        error={error}
                        label="SEO keywords"  
                    />
                )}
            />
            <CustomButton type="submit">Зберегти</CustomButton>
        </form>
    )
}

export default SiteInfo