import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import DropZone from "../../Common/DropZone/DropZone"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Field from "../../UI/Field/Field"
import CustomInput from "../../UI/Form/Input"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'

const ClientCreate = (props) => {
    const { onClose, addClient } = props

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        console.log(data)
        addClient(data)
    }

    useEffect(() => {
        reset({
            name: "",
            city: "",
            image: null
        })
    }, [])

    return (
        <Modal title="Новий клієнт" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Обов'язкове поле!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Назва"  
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Місто"  
                            />
                        )}
                    />
                </Field>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`image`}
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Зображення"
                                id={1}
                            />
                        )}
                    />
                </div>
                <CustomButton type="submit">Додати</CustomButton>
            </form>
        </Modal>
    )
}

export default ClientCreate