import { videosApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_VIDEOS_DATA = "SET_VIDEOS_DATA"
const SET_NEW_VIDEO = "SET_NEW_VIDEO"
const SET_TOTAL_VIDEOS = "SET_TOTAL_VIDEOS"


let initialState = {
    videos: [],
    total: 0,
    newVideo: null
}

const videosReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_VIDEOS_DATA: {
            return { ...state, videos: action.videos }
        }
        case SET_TOTAL_VIDEOS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_VIDEO: {
            return { ...state, newVideo: action.newVideo }
        }
        default:
            return state
    }
}

export const setVideosData = (videos) => ({
    type: SET_VIDEOS_DATA, videos
})
export const setNewVideo = (newVideo) => ({
    type: SET_NEW_VIDEO, newVideo
})
export const setTotalVideos = (total) => ({
    type: SET_TOTAL_VIDEOS, total
})

export const getVideos = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await videosApi.getVideos()
        dispatch([setTotalVideos(response.total), setVideosData(response.videos), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createVideo = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await videosApi.createVideo(data)
        dispatch([setServerResponse(response.message), setNewVideo(response.video), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateVideo = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await videosApi.updateVideo(id, data)
        dispatch([setServerResponse(response.message), setNewVideo(response.video), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deleteVideo = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await videosApi.deleteVideo(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default videosReducer