import { TextField } from '@mui/material'
import React from 'react'

const CustomSelect = (props) => {
    const { 
        value, 
        onChange, 
        label, 
        error, 
        children, 
        variant = "outlined",
    } = props

    const material =  {
        width: "100%",
        '& .MuiMenuItem-root .MuiMenuItem-gutters .MuiButtonBase-root': {
            display: "flex !important",
        },
        '& .MuiMenuItem-root': {
            display: "flex"
        },
        '& label.Mui-focused': {
            color: '#EF4444'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EF4444' 
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(239, 68, 68, .3)"
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(239, 68, 68, .6)"
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "4px",
            backgroundColor: "white"
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            marginTop: "10px",
        },
        '& .MuiList-root .MuiList-padding .MuiMenu-list': {
            display: "flex",
            flexDirection: "column"
        }
    }

    return (
        <TextField
            value={value}
            label={label}
            autoComplete="off"
            error={!!error}
            variant={variant}
            helperText={error ? error.message : null}
            onChange={onChange}
            select  
            sx={material}
            {...props}
        >
            {children}
        </TextField>
    )
}

export default CustomSelect
