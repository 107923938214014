import { MenuItem } from "@mui/material"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import DropZone from "../../Common/DropZone/DropZone"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Field from "../../UI/Field/Field"
import CustomInput from "../../UI/Form/Input"
import CustomMarkdown from "../../UI/Form/Markdown/CustomMarkdown"
import CustomSelect from "../../UI/Form/Select"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'

const ConstructionCreate = (props) => {
    const { onClose, addConstruction } = props

    const { handleSubmit, reset, control, watch } = useForm()

    const onSubmit = (data) => {
        addConstruction(data)
    }

    const watchedType = watch('type');

    useEffect(() => {
        if (localStorage.getItem("add_const_info")) {
            const rememberedInfo = JSON.parse(
                localStorage.getItem("add_const_info")
            )

            if (rememberedInfo) {
                reset({ ...rememberedInfo })
            }
        }
    }, [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            localStorage.setItem("add_const_info", JSON.stringify(value))
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <Modal title="Нова конструкція" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Обов'язкове поле!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Назва"  
                            />
                        )}
                    />
                </Field>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`image`}
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Зображення"
                                id={1}
                            />
                        )}
                    />
                </div>
                <Field>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomSelect
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Тип"
                            >
                                <MenuItem value="light">Світлопрозорі конструкції</MenuItem>
                                <MenuItem value="al">Алюмінієві конструкції</MenuItem>
                            </CustomSelect>
                        )}
                    />
                </Field>
                {watchedType === "light" && (
                    <Field>
                        <Controller
                            name="brand"
                            control={control}
                            rules={{ required: "Обов'язкове поле!" }}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <CustomSelect
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    label="Бренд"
                                >
                                    <MenuItem value="viknaroff">VIKNAR'OFF</MenuItem>
                                    <MenuItem value="rehau">REHAU</MenuItem>
                                </CustomSelect>
                            )}
                        />
                    </Field>
                )}
                <Field>
                    <Controller
                        name="info"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Обов'язкове поле!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomMarkdown
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Основна інформація"  
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="dop_info"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomMarkdown
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Додаткова інформація"  
                            />
                        )}
                    />
                </Field>
                <CustomButton type="submit">Додати</CustomButton>
                <Field></Field>
            </form>
        </Modal>
    )
}

export default ConstructionCreate