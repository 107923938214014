import { ordersApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_ORDERS_DATA = "SET_ORDERS_DATA"
const SET_NEW_ORDER = "SET_NEW_ORDER"
const SET_TOTAL_ORDERS = "SET_TOTAL_ORDERS"


let initialState = {
    orders: [],
    total: 0,
    newOrder: null
}

const ordersReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ORDERS_DATA: {
            return { ...state, orders: action.orders }
        }
        case SET_TOTAL_ORDERS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_ORDER: {
            return { ...state, newOrder: action.newOrder }
        }
        default:
            return state
    }
}

export const setOrdersData = (orders) => ({
    type: SET_ORDERS_DATA, orders
})
export const setNewOrder = (newOrder) => ({
    type: SET_NEW_ORDER, newOrder
})
export const setTotalOrders = (total) => ({
    type: SET_TOTAL_ORDERS, total
})

export const getOrders = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.getOrders(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalOrders(response.total), setOrdersData(response.orders), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const updateOrder = (id, status) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.updateOrder(id, status)
        dispatch([setServerResponse(response.message), setNewOrder(response.order), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deleteOrder = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await ordersApi.deleteOrder(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default ordersReducer