import { constructionsApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_CONSTRUCTIONS_DATA = "SET_CONSTRUCTIONS_DATA"
const SET_NEW_CONSTRUCTION = "SET_NEW_CONSTRUCTION"
const SET_TOTAL_CONSTRUCTIONS = "SET_TOTAL_CONSTRUCTIONS"


let initialState = {
    constructions: [],
    total: 0,
    newConstruction: null
}

const constructionsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CONSTRUCTIONS_DATA: {
            return { ...state, constructions: action.constructions }
        }
        case SET_TOTAL_CONSTRUCTIONS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_CONSTRUCTION: {
            return { ...state, newConstruction: action.newConstruction }
        }
        default:
            return state
    }
}

export const setConstructionsData = (constructions) => ({
    type: SET_CONSTRUCTIONS_DATA, constructions
})
export const setNewConstruction = (newConstruction) => ({
    type: SET_NEW_CONSTRUCTION, newConstruction
})
export const setTotalConstructions = (total) => ({
    type: SET_TOTAL_CONSTRUCTIONS, total
})

export const getConstructions = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await constructionsApi.getConstructions()
        dispatch([setTotalConstructions(response.total), setConstructionsData(response.constructions), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createConstruction = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await constructionsApi.createConstruction(data)
        dispatch([setServerResponse(response.message), setNewConstruction(response.construction), setIsFetching(false)])
        localStorage.add_const_info = ""
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateConstruction = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await constructionsApi.updateConstruction(id, data)
        dispatch([setServerResponse(response.message), setNewConstruction(response.construction), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deleteConstruction = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await constructionsApi.deleteConstruction(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default constructionsReducer