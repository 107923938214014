import { slidesApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_CLIENTS_DATA = "SET_CLIENTS_DATA"
const SET_NEW_CLIENT = "SET_NEW_CLIENT"
const SET_TOTAL_CLIENTS = "SET_TOTAL_CLIENTS"


let initialState = {
    slides: [],
    total: 0,
    newSlide: null
}

const slidesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CLIENTS_DATA: {
            return { ...state, slides: action.slides }
        }
        case SET_TOTAL_CLIENTS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_CLIENT: {
            return { ...state, newSlide: action.newSlide }
        }
        default:
            return state
    }
}

export const setSlidesData = (slides) => ({
    type: SET_CLIENTS_DATA, slides
})
export const setNewSlide = (newSlide) => ({
    type: SET_NEW_CLIENT, newSlide
})
export const setTotalSlides = (total) => ({
    type: SET_TOTAL_CLIENTS, total
})

export const getSlides = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await slidesApi.getSlides()
        dispatch([setTotalSlides(response.total), setSlidesData(response.slides), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createSlide = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await slidesApi.createSlide(data)
        dispatch([setServerResponse(response.message), setNewSlide(response.slide), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateSlide = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await slidesApi.updateSlide(id, data)
        dispatch([setServerResponse(response.message), setNewSlide(response.slide), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deleteSlide = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await slidesApi.deleteSlide(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default slidesReducer