import classes from './PreviewSlide.module.css'

const PreviewSlide = (props) => {
    const { item } = props

    return (
        <div
            className={classes.main}
            style={{
                backgroundImage: `url(${item.bg})`
            }}
        >
            <div className={classes.black}/>
            {item.images.map(el => (
                <div className={classes.imgContainer}>
                    <img src={el} alt="slide"/>
                </div>
            ))}
        </div>

    )
}

export default PreviewSlide