import React, { useEffect } from 'react'
import { FiSearch } from 'react-icons/fi';
import useDebounce from '../../../../Hooks/useDebounce';
import CustomInput from '../../Form/Input';

const AdminSearch = (props) => {
    const { onSearch, pageSize, setSearchValue, searchValue, filter = false, isIncludeFreeTickets } = props

    const debouncedSearchTerm = useDebounce(searchValue, 500);

    const handleValue = (value) => {
        setSearchValue(value)
    } 

    useEffect(() => {
        if(debouncedSearchTerm){
            onSearch(0 + 1, pageSize, "", "", searchValue, filter, isIncludeFreeTickets)  
        }
    }, [debouncedSearchTerm])

    useEffect(() => {
        if(searchValue.length === 0 && debouncedSearchTerm){
            onSearch(0 + 1, pageSize, "", "", "", filter, isIncludeFreeTickets)  
        }
    }, [searchValue])

    return (
        <CustomInput
            label="Пошук"
            placeholder="Напишіть щось"
            onChange={handleValue}
            value={searchValue}
            startAdornment={true}
            startAdornmentIcon={<FiSearch/>}
        />
    )
}

export default AdminSearch