import { clientsApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_CLIENTS_DATA = "SET_CLIENTS_DATA"
const SET_NEW_CLIENT = "SET_NEW_CLIENT"
const SET_TOTAL_CLIENTS = "SET_TOTAL_CLIENTS"


let initialState = {
    clients: [],
    total: 0,
    newClient: null
}

const clientsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CLIENTS_DATA: {
            return { ...state, clients: action.clients }
        }
        case SET_TOTAL_CLIENTS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_CLIENT: {
            return { ...state, newClient: action.newClient }
        }
        default:
            return state
    }
}

export const setClientsData = (clients) => ({
    type: SET_CLIENTS_DATA, clients
})
export const setNewClient = (newClient) => ({
    type: SET_NEW_CLIENT, newClient
})
export const setTotalClients = (total) => ({
    type: SET_TOTAL_CLIENTS, total
})

export const getClients = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await clientsApi.getClients()
        dispatch([setTotalClients(response.total), setClientsData(response.clients), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createClient = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await clientsApi.createClient(data)
        dispatch([setServerResponse(response.message), setNewClient(response.client), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateClient = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await clientsApi.updateClient(id, data)
        dispatch([setServerResponse(response.message), setNewClient(response.client), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deleteClient = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await clientsApi.deleteClient(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default clientsReducer