import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createConstruction, deleteConstruction, getConstructions, setNewConstruction, setConstructionsData, setTotalConstructions, updateConstruction } from "../../../Redux/constructionsReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import ConstructionCreate from "./ConstructionCreate"
import Constructions from "./Constructions"
import ConstructionUpdate from "./ConstructionUpdate"

const ConstructionsContainer = (props) => {
    const {
        isFetching,
        getConstructions,
        createConstruction,
        setConstructionsData,
        setNewConstruction,
        newConstruction,
        constructions,
        total,
        updateConstruction,
        deleteConstruction,
        setTotalConstructions
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createConstruction(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (constructionId) => {
        deleteConstruction(constructionId).then((status) => {
            if(status) {
                const newConstructions = [...constructions]
                newConstructions.forEach((item, index) => {
                    if(item._id === constructionId){
                        newConstructions.splice(index, 1)
                        setTotalConstructions(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setConstructionsData(newConstructions)
            }
        })
    }

    useEffect(() => {
        if(newConstruction){
            const newConstructions = [...constructions]
            let pushIndex = newConstructions.length
            newConstructions.forEach((item, index) => {
                if(item._id === newConstruction._id){
                    newConstructions.splice(index, 1)
                    pushIndex = index
                }
            })
            newConstructions.splice(pushIndex, 0, newConstruction)
            setConstructionsData(newConstructions)
            setTotalConstructions(total + 1)
            setNewConstruction(null)
        }
    }, [newConstruction])

    useEffect(() => {
        getConstructions(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Конструкції" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getConstructions}
                searchBar={false}
            />
            {isOpenAdd && <ConstructionCreate onClose={handleOpenAdd} addConstruction={handleAdd}/>}
            {isOpenEdit && <ConstructionUpdate onClose={handleOpenEdit} editConstruction={updateConstruction} construction={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.name}
                />
            )}
            {isFetching && <Preloader/>}
            {constructions && (
                <Constructions
                    constructions={constructions}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    total={total}
                    handleChangePage={handleChangePage}
                    handlePageSize={handlePageSize}
                    handleEdit={handleOpenEdit}
                    handleRemove={handleOpenRemove}
                    onAddClick={handleOpenAdd}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    getConstructions={getConstructions}
                />
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newConstruction: state.constructions.newConstruction,
    constructions: state.constructions.constructions,
    total: state.constructions.total
})

export default connect(mapStateToProps, {
    getConstructions,
    createConstruction,
    updateConstruction,
    deleteConstruction,
    setNewConstruction,
    setTotalConstructions,
    setConstructionsData
})(ConstructionsContainer)