"use client"
import { HiMenu } from "react-icons/hi"
import { IconButton, Drawer } from "@mui/material";
import { AiOutlineClose } from 'react-icons/ai';
import { useState } from "react";
import classes from "./Burger.module.css"
import { NavLink } from "react-router-dom";
import { useAdminSidebar } from "../../../../../Hooks/useAdminSidebar";

const Burger = () => {
    const [isOpenBurger, setIsOpenBurger] = useState(false)

    const handleOpen = () => {
        setIsOpenBurger(!isOpenBurger)
    }

    const menuItems = useAdminSidebar()

    const anchor = "left"

    return (
        <div className={classes.main}>
            <div className={classes.burger}>
                <IconButton onClick={handleOpen}>
                    <HiMenu />
                </IconButton>
            </div>
            <Drawer
                anchor={anchor}
                open={isOpenBurger}
                onClose={handleOpen}
                classes={{ root: classes.root, paper: classes.paper }}
                sx={{ zIndex: 199999999 }}
            >
                <div className={classes.header}>
                    <IconButton
                        onClick={handleOpen}
                        className={classes.closeBut}
                    >
                        <AiOutlineClose />
                    </IconButton>
                </div>
                <div className={classes.links}>
                    {menuItems.map(item => (
                        <NavLink
                            to={item.href}
                            onClick={handleOpen}
                            key={item.title}
                        >
                            <span>{item.title}</span>
                        </NavLink>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

export default Burger