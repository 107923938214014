import { Controller, useForm } from "react-hook-form"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Field from "../../UI/Field/Field"
import CustomInput from "../../UI/Form/Input"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'

const VideoCreate = (props) => {
    const { onClose, addVideo } = props

    const { handleSubmit, control } = useForm()

    const onSubmit = (data) => {
        addVideo(data)
    }

    return (
        <Modal title="Додати відео" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="url"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Обов'язкове поле!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomInput
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Посилання на відео з YouTube"  
                            />
                        )}
                    />
                </Field>
                <CustomButton type="submit">Додати</CustomButton>
            </form>
        </Modal>
    )
}

export default VideoCreate