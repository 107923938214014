import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createVideo, deleteVideo, getVideos, setNewVideo, setVideosData, setTotalVideos, updateVideo } from "../../../Redux/videosReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import VideoCreate from "./VideoCreate"
import Videos from "./Videos"
import VideoUpdate from "./VideoUpdate"

const VideosContainer = (props) => {
    const {
        isFetching,
        getVideos,
        createVideo,
        setVideosData,
        setNewVideo,
        newVideo,
        videos,
        total,
        updateVideo,
        deleteVideo,
        setTotalVideos
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createVideo(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (videoId) => {
        deleteVideo(videoId).then((status) => {
            if(status) {
                const newVideos = [...videos]
                newVideos.forEach((item, index) => {
                    if(item._id === videoId){
                        newVideos.splice(index, 1)
                        setTotalVideos(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setVideosData(newVideos)
            }
        })
    }

    useEffect(() => {
        if(newVideo){
            const newVideos = [...videos]
            let pushIndex = newVideos.length
            newVideos.forEach((item, index) => {
                if(item._id === newVideo._id){
                    newVideos.splice(index, 1)
                    pushIndex = index
                }
            })
            newVideos.splice(pushIndex, 0, newVideo)
            setVideosData(newVideos)
            setTotalVideos(total + 1)
            setNewVideo(null)
        }
    }, [newVideo])

    useEffect(() => {
        getVideos()
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Відео" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getVideos}
                searchBar={false}
            />
            {isOpenAdd && <VideoCreate onClose={handleOpenAdd} addVideo={handleAdd}/>}
            {isOpenEdit && <VideoUpdate onClose={handleOpenEdit} editVideo={updateVideo} video={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.name}
                />
            )}
            {isFetching && <Preloader/>}
            {videos && (
                <Videos
                    videos={videos}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    total={total}
                    handleChangePage={handleChangePage}
                    handlePageSize={handlePageSize}
                    handleEdit={handleOpenEdit}
                    handleRemove={handleOpenRemove}
                    onAddClick={handleOpenAdd}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    getVideos={getVideos}
                />
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newVideo: state.videos.newVideo,
    videos: state.videos.videos,
    total: state.videos.total
})

export default connect(mapStateToProps, {
    getVideos,
    createVideo,
    updateVideo,
    deleteVideo,
    setNewVideo,
    setTotalVideos,
    setVideosData
})(VideosContainer)