import { RiAlignItemTopLine } from "react-icons/ri";
import { FaUsers, FaVideo, FaThList } from "react-icons/fa";
import { TfiLayoutSlider } from "react-icons/tfi";
import { IoSettingsSharp } from "react-icons/io5";

export const useAdminSidebar = () => {
    const items = [
        {
            title: "Заявки",
            icon: <FaThList/>,
            href: "/"
        },
        {
            title: "Клієнти",
            icon: <FaUsers/>,
            href: "/clients"
        },
        {
            title: "Конструкції",
            icon: <RiAlignItemTopLine/>,
            href: "/constructions"
        },
        {
            title: "Слайди",
            icon: <TfiLayoutSlider/>,
            href: "/slides"
        },
        {
            title: "Відео",
            icon: <FaVideo/>,
            href: "/videos"
        },
        {
            title: "SEO",
            icon: <IoSettingsSharp/>,
            href: "/settings"
        }
    ]

    return items
}