import { useState } from "react"
import classes from "./StatusDropdown.module.css"
import { MdArrowDropDown } from "react-icons/md";
import { useRef } from "react";
import { useEffect } from "react";

const options = [
    {
        title: "Новий",
        value: "new"
    },
    {
        title: "Підтверджено",
        value: "good"
    },
    {
        title: "Не підтверджено",
        value: "bad"
    }
]

const StatusDropdown = (props) => {
    const { value, zIndex, onSelect, orderId } = props
    const [isOpen, setIsOpen] = useState(false)

    const modalRef = useRef();

    const [selectedValue, setSelectedValue] = useState(value)

    const onClick = (selValue) => {
        setSelectedValue(selValue)
        onSelect(orderId, selValue)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={classes.main} style={{ zIndex: zIndex }} ref={modalRef}>
            <div className={`${classes.placeholder} ${classes[selectedValue]} ${isOpen ? classes.isOpen : ""}`} onClick={() => setIsOpen(true)}>
                <span>{options.find(el => el.value === selectedValue).title}</span>
                <MdArrowDropDown/>
            </div>
            {isOpen && (
                <div className={classes.menu}>
                    {options.map(el => (
                        el.value !== selectedValue && (
                            <div className={`${classes.option} ${classes[el.value]}`} onClick={() => onClick(el.value)}>
                                <span>{el.title}</span>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    )
}

export default StatusDropdown