import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createSlide, deleteSlide, getSlides, setNewSlide, setSlidesData, setTotalSlides, updateSlide } from "../../../Redux/slidesReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import SlideCreate from "./SlideCreate"
import SlideUpdate from "./SlideUpdate"
import Slides from "./Slides"

const SlidesContainer = (props) => {
    const {
        isFetching,
        getSlides,
        createSlide,
        setSlidesData,
        setNewSlide,
        newSlide,
        slides,
        total,
        updateSlide,
        deleteSlide,
        setTotalSlides
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createSlide(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (slideId) => {
        deleteSlide(slideId).then((status) => {
            if(status) {
                const newSlides = [...slides]
                newSlides.forEach((item, index) => {
                    if(item._id === slideId){
                        newSlides.splice(index, 1)
                        setTotalSlides(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setSlidesData(newSlides)
            }
        })
    }

    useEffect(() => {
        if(newSlide){
            const newSlides = [...slides]
            let pushIndex = newSlides.length
            newSlides.forEach((item, index) => {
                if(item._id === newSlide._id){
                    newSlides.splice(index, 1)
                    pushIndex = index
                }
            })
            newSlides.splice(pushIndex, 0, newSlide)
            setSlidesData(newSlides)
            setTotalSlides(total + 1)
            setNewSlide(null)
        }
    }, [newSlide])

    useEffect(() => {
        getSlides()
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Слайди" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getSlides}
                searchBar={false}
            />
            {isOpenAdd && <SlideCreate onClose={handleOpenAdd} addSlide={handleAdd}/>}
            {isOpenEdit && <SlideUpdate onClose={handleOpenEdit} editSlide={updateSlide} slide={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={"Слайд"}
                />
            )}
            {isFetching && <Preloader/>}
            {slides && (
                <Slides
                    slides={slides}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    total={total}
                    handleChangePage={handleChangePage}
                    handlePageSize={handlePageSize}
                    handleEdit={handleOpenEdit}
                    handleRemove={handleOpenRemove}
                    onAddClick={handleOpenAdd}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    getSlides={getSlides}
                />
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newSlide: state.slides.newSlide,
    slides: state.slides.slides,
    total: state.slides.total
})

export default connect(mapStateToProps, {
    getSlides,
    createSlide,
    updateSlide,
    deleteSlide,
    setNewSlide,
    setTotalSlides,
    setSlidesData
})(SlidesContainer)