import { useEffect } from "react"
import { connect } from "react-redux"
import { getSiteInfo, updateSiteInfo } from "../../../Redux/commonReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import SiteInfo from "./SiteInfo"

const SiteInfoContainer = (props) => {
    const {
        isFetching,
        site_info,
        getSiteInfo,
        updateSiteInfo
    } = props

    useEffect(() => {
        getSiteInfo()
    }, [])

    return (
        <AdminLayout>
            {isFetching && <Preloader/>}
            <PageHeader
                title="SEO"
                searchBar={false}
                createBut={false}
            />
            {site_info && (
                <SiteInfo site_info={site_info} updateSiteInfo={updateSiteInfo}/>
            )}
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    site_info: state.common.site_info
})

export default connect(mapStateToProps, {
    getSiteInfo,
    updateSiteInfo
})(SiteInfoContainer)