import { siteInfoApi } from "../Api/api"

const SET_IS_FETCHING = 'SET_IS_FETCHING'
const SET_SERVER_ERROR = 'SET_SERVER_ERROR'
const SET_SERVER_RESPONSE = 'SET_SERVER_RESPONSE'
const SET_SITE_INFO_DATA = "SET_SITE_INFO_DATA"

let initialState = {
    isFetching: false,
    serverError: null,
    serverResponse: null,
    site_info: null
}

let commonReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IS_FETCHING: {
            return { ...state, isFetching: action.isFetching }
        }
        case SET_SERVER_ERROR: {
            return { ...state, serverError: action.serverError }
        }
        case SET_SERVER_RESPONSE: {
            return { ...state, serverResponse: action.serverResponse }
        }
        case SET_SITE_INFO_DATA: {
            return { ...state, site_info: action.site_info }
        }
        default: 
            return state
    }
}

export const setIsFetching = (isFetching) => ({
    type: SET_IS_FETCHING, isFetching
})
export const setServerError = (serverError) => ({
    type: SET_SERVER_ERROR, serverError
})
export const setServerResponse = (serverResponse) => ({
    type: SET_SERVER_RESPONSE, serverResponse
})
export const setSiteInfoData = (site_info) => ({
    type: SET_SITE_INFO_DATA, site_info
})

export const getSiteInfo = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await siteInfoApi.getSiteInfo()
        dispatch([setSiteInfoData(response.site_info), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const updateSiteInfo = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await siteInfoApi.updateSiteInfo(data)
        dispatch([setServerResponse(response.message), setSiteInfoData(response.site_info), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export default commonReducer