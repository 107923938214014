import { MenuItem } from "@mui/material"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import DropZone from "../../Common/DropZone/DropZone"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Field from "../../UI/Field/Field"
import CustomSelect from "../../UI/Form/Select"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'

const SlideCreate = (props) => {
    const { onClose, addSlide } = props

    const { handleSubmit, control, reset } = useForm()

    const onSubmit = (data) => {
        addSlide(data)
    }

    useEffect(() => {
        reset({
            type: "",
            bg: null,
            images: []
        })
    }, [])

    return (
        <Modal title="Нова конструкція" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomSelect
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Тип"
                            >
                                <MenuItem value="karp">Карпорти</MenuItem>
                                <MenuItem value="terrace">Тераси</MenuItem>
                            </CustomSelect>
                        )}
                    />
                </Field>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`bg`}
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Бекграунд"
                                id={1}
                            />
                        )}
                    />
                </div>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`images`}
                        control={control}
                        rules={{ required: "Обов'язкове поле!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Зображення"
                                multiple={true}
                                id={2}
                            />
                        )}
                    />
                </div>
                <CustomButton type="submit">Додати</CustomButton>
                <Field></Field>
            </form>
        </Modal>
    )
}

export default SlideCreate